import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/jenkins/workspace/canvas-site-deploy/canvas-site/src/components/MDXLayout.tsx";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const Do = makeShortcode("Do");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "phone-numbers"
    }}>{`Phone Numbers`}</h1>
    <p>{`U.S. phone numbers should be formatted as follows:`}</p>
    <ul>
      <li parentName="ul">{`415-123-1234`}</li>
    </ul>
    <p>{`For international phone numbers, insert a plus sign before the country code, followed by the rest of
the number. Note that using or not using dashes depends on the country:`}</p>
    <Do mdxType="Do">
      <ul>
        <li parentName="ul">{`Ireland: +353 1 2419900`}</li>
        <li parentName="ul">{`UK: +44 (0) 20 3318 2336`}</li>
        <li parentName="ul">{`Auckland: +64-9-8010520`}</li>
        <li parentName="ul">{`Singapore: +65 6809 5098`}</li>
        <li parentName="ul">{`Japan: +81-3-4572-1200`}</li>
      </ul>
    </Do>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      